const POST_DATA_COMPANY_LOADING = 'venue/POST_DATA_COMPANY_LOADING';
const POST_DATA_COMPANY_SUCCESS = 'venue/POST_DATA_COMPANY_SUCCESS';
const POST_DATA_COMPANY_ERROR = 'venue/POST_DATA_COMPANY_ERROR';

const GET_DATA_COMPANY_LOADING = 'venue/GET_DATA_COMPANY_LOADING';
const GET_DATA_COMPANY_SUCCESS = 'venue/GET_DATA_COMPANY_SUCCESS';
const GET_DATA_COMPANY_ERROR = 'venue/GET_DATA_COMPANY_ERROR';

const GET_DATA_PROVINCE_LOADING = 'venue/GET_DATA_PROVINCE_LOADING';
const GET_DATA_PROVINCE_SUCCESS = 'venue/GET_DATA_PROVINCE_SUCCESS';
const GET_DATA_PROVINCE_ERROR = 'venue/GET_DATA_PROVINCE_ERROR';

const GET_DATA_CITY_BY_PROVINCE_LOADING = 'venue/GET_DATA_CITY_BY_PROVINCE_LOADING';
const GET_DATA_CITY_BY_PROVINCE_SUCCESS = 'venue/GET_DATA_CITY_BY_PROVINCE_SUCCESS';
const GET_DATA_CITY_BY_PROVINCE_ERROR = 'venue/GET_DATA_CITY_BY_PROVINCE_ERROR'

export {
  POST_DATA_COMPANY_LOADING,
  POST_DATA_COMPANY_SUCCESS,
  POST_DATA_COMPANY_ERROR,
  GET_DATA_COMPANY_LOADING,
  GET_DATA_COMPANY_SUCCESS,
  GET_DATA_COMPANY_ERROR,
  GET_DATA_PROVINCE_LOADING,
  GET_DATA_PROVINCE_SUCCESS,
  GET_DATA_PROVINCE_ERROR,
  GET_DATA_CITY_BY_PROVINCE_LOADING,
  GET_DATA_CITY_BY_PROVINCE_SUCCESS,
  GET_DATA_CITY_BY_PROVINCE_ERROR
}