const GET_SUM_ORDER_LOADING = 'GET_ORDER_LOADING';
const GET_SUM_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
const GET_SUM_ORDER_FAILED = 'GET_ORDER_FAILED';

const GET_CITY_LOADING = 'GET_CITY_LOADING'
const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
const GET_CITY_FAILED = 'GET_CITY_FAILED';

const GET_COMPANY_LOADING = 'GET_COMPANY_LOADING'
const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED';

const GET_ALLSUM_LOADING = 'GET_ALLSUM_LOADING';
const GET_ALLSUM_SUCCESS = 'GET_ALLSUM_SUCCESS';
const GET_ALLSUM_FAILED = 'GET_ALLSUM_FAILED';

export {
  GET_SUM_ORDER_LOADING,
  GET_SUM_ORDER_SUCCESS,
  GET_SUM_ORDER_FAILED,
  GET_CITY_LOADING,
  GET_CITY_SUCCESS,
  GET_CITY_FAILED,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILED,
  GET_ALLSUM_LOADING,
  GET_ALLSUM_SUCCESS,
  GET_ALLSUM_FAILED,
};
