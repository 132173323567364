import types from '../constants'

const initialState = {
  company: {
    meta: {
      status: 'loading'
    },
    data: []
  },
  venueById: {
    meta: {
      status: 'loading'
    },
    data: []
  },
  fetchVenue: {
    meta: {
      status: 'loading'
    },
    data: []
  },
  province: {
    meta: {
      status: 'loading'
    },
    data: []
  },
  cityById: {
    meta: {
      status: 'loading'
    },
    data: []
  }
}

export default function editVenue(state = initialState, action) {
  switch(action.type) {
  case types.GET_DATA_COMPANY_LOADING:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.GET_DATA_COMPANY_SUCCESS:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.GET_DATA_COMPANY_ERROR:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.GET_DATA_VENUE_BY_ID_LOADING:
    return {
      ...state,
      venueById: {
        ...action.payload
      }
    }
  case types.GET_DATA_VENUE_BY_ID_SUCCESS:
    return {
      ...state,
      venueById: {
        ...action.payload
      }
    }
  case types.GET_DATA_VENUE_BY_ID_ERROR:
    return {
      ...state,
      venueById: {
        ...action.payload
      }
    }
  case types.FETCH_DATA_VENUE_LOADING:
    return {
      ...state,
      fetchVenue: {
        ...action.payload
      }
    }
  case types.FETCH_DATA_VENUE_SUCCESS:
    return {
      ...state,
      fetchVenue: {
        ...action.payload
      }
    }
  case types.FETCH_DATA_VENUE__ERROR:
    return {
      ...state,
      fetchVenue: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_LOADING:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_SUCCESS:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_ERROR:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_LOADING:
    return {
      ...state,
      cityById: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_SUCCESS:
    return {
      ...state,
      cityById: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_ERROR:
    return {
      ...state,
      cityById: {
        ...action.payload
      }
    }
  default:
    return { ...initialState, ...state }
  }
}