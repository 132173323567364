const GET_DATA_COMPANY_LOADING = 'editCompany/GET_DATA_COMPANY_LOADING';
const GET_DATA_COMPANY_SUCCESS = 'editCompany/GET_DATA_COMPANY_SUCCESS';
const GET_DATA_COMPANY_ERROR = 'editCompany/GET_DATA_COMPANY_ERROR';

const GET_DATA_COMPANY_BY_ID_LOADING = 'editCompany/GET_DATA_COMPANY_BY_ID_LOADING';
const GET_DATA_COMPANY_BY_ID_SUCCESS = 'editCompany/GET_DATA_COMPANY_BY_ID_SUCCESS';
const GET_DATA_COMPANY_BY_ID_ERROR = 'editCompany/GET_DATA_COMPANY_BY_ID_ERROR';

const POST_DATA_COMPANY_LOADING = 'editCompany/POST_DATA_COMPANY_LOADING';
const POST_DATA_COMPANY_SUCCESS = 'editCompany/POST_DATA_COMPANY_SUCCESS';
const POST_DATA_COMPANY_ERROR = 'editCompany/POST_DATA_COMPANY_ERROR';

const FETCH_DATA_COMPANY_LOADING = 'editCompany/FETCH_DATA_COMPANY_LOADING';
const FETCH_DATA_COMPANY_SUCCESS = 'editCompany/FETCH_DATA_COMPANY_SUCCESS';
const FETCH_DATA_COMPANY_ERROR = 'editCompany/FETCH_DATA_COMPANY_ERROR';

const GET_DATA_PROVINCE_LOADING = 'editCompany/GET_DATA_PROVINCE_LOADING';
const GET_DATA_PROVINCE_SUCCESS = 'editCompany/GET_DATA_PROVINCE_SUCCESS';
const GET_DATA_PROVINCE_ERROR = 'editCompany/GET_DATA_PROVINCE_ERROR';

const GET_DATA_CITY_BY_PROVINCE_LOADING = 'editCompany/GET_DATA_CITY_BY_PROVINCE_LOADING';
const GET_DATA_CITY_BY_PROVINCE_SUCCESS = 'editCompany/GET_DATA_CITY_BY_PROVINCE_SUCCESS';
const GET_DATA_CITY_BY_PROVINCE_ERROR = 'editCompany/GET_DATA_CITY_BY_PROVINCE_ERROR';

export {
    GET_DATA_COMPANY_LOADING,
    GET_DATA_COMPANY_SUCCESS,
    GET_DATA_COMPANY_ERROR,
    GET_DATA_COMPANY_BY_ID_LOADING,
    GET_DATA_COMPANY_BY_ID_SUCCESS,
    GET_DATA_COMPANY_BY_ID_ERROR,
    POST_DATA_COMPANY_LOADING,
    POST_DATA_COMPANY_SUCCESS,
    POST_DATA_COMPANY_ERROR,
    FETCH_DATA_COMPANY_LOADING,
    FETCH_DATA_COMPANY_SUCCESS,
    FETCH_DATA_COMPANY_ERROR,
    GET_DATA_PROVINCE_LOADING,
    GET_DATA_PROVINCE_SUCCESS,
    GET_DATA_PROVINCE_ERROR,
    GET_DATA_CITY_BY_PROVINCE_LOADING,
    GET_DATA_CITY_BY_PROVINCE_SUCCESS,
    GET_DATA_CITY_BY_PROVINCE_ERROR,
}