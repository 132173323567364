import types from '../constants';

const initialState = {
  meta: {
    status: ''
  },
  data: []
};

export default function order(state = initialState, action) {
  switch (action.type) {
  case types.GET_ORDER_DETAIL_LOADING:
    return {
      ...state,
      ...action.payload
    };
  case types.GET_ORDER_DETAIL_SUCCESS:
    return {
      ...state,
      ...action.payload
    };
  case types.GET_ORDER_DETAIL_FAILED:
    return {
      ...state,
      ...action.payload
    };
  default:
    return {
      ...state
    };
  }
}
