/* eslint-disable import/prefer-default-export */
import * as user from './user';
import * as order from './order';
import * as venue from './venue';
import * as venueType from './venueType';
import * as orderDetail from './orderDetail';
import * as orderReview from './orderReview';
import * as doPayment from './doPayment';
import * as licenseCheck from './licenseCheck';
import * as sendCertificate from './sendCertificate';
import * as isAdmin from './isAdmin';
import * as toShow from './toShow';
import * as editCompany from './editCompany';
import * as editVenue from './editVenue';
import * as editVenueType from './editVenueType';
import * as regionalAgents from './regionalAgents';
import * as deleteVenue from './deleteVenue';
import * as loadingComponent from './loading';
import * as location from './location'
import * as search from './search'
import * as detailVenue from './detailVenue'
const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export default {
  SET_RUNTIME_VARIABLE,
  ...user,
  ...order,
  ...venueType,
  ...venue,
  ...orderDetail,
  ...orderReview,
  ...doPayment,
  ...licenseCheck,
  ...sendCertificate,
  ...isAdmin,
  ...toShow,
  ...editCompany,
  ...editVenue,
  ...editVenueType,
  ...regionalAgents,
  ...deleteVenue,
  ...loadingComponent,
  ...location,
  ...search,
  ...detailVenue
};
