import types from '../constants';

const initialState = {
  meta: {
    status: ''
  },
  data: [
    
  ]
};

export default function deleteVenue(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_VENUE_BY_ID_LOADING:
      return {
        ...state,
        ...action.payload
      };
    case types.DELETE_VENUE_BY_ID_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case types.DELETE_VENUE_BY_ID_FAILED:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
}
