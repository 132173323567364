import types from '../constants';

const initialState = {
  meta: {
    status: ''
  },
  data: []
};

export default function loading(state = initialState, action) {
  switch (action.type) {
  case types.LOADING_COMPONENT_LOADING:
    return {
      ...state,
      ...action.payload
    };
  case types.LOADING_COMPONENT_SUCCESS:
    return {
      ...state,
      ...action.payload
    };
  case types.LOADING_COMPONENT_FAILED:
    return {
      ...state,
      ...action.payload
    };
  default:
    return {
      ...state
    };
  }
}
