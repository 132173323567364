import types from '../constants';

const initialState ={
  meta:{
    status: 'loadig'
  },
  data:[]
};


export default function detailVenue (state = initialState, action){
  switch(action.type){
  case types.DETAIL_VENUE_BY_ID_LOADING:
    return{
      ...state,
      ...action.payload
    };
  case types.DETAIL_VENUE_BY_ID_SUCCESS:
    return{
      ...state,
      ...action.payload
    };
  case types.DETAIL_VENUE_BY_ID_FAILED:
    return{
      ...state,
      ...action.payload
    };
  default:
    return{
      ...state
    };
  }
}