const GET_REGIONAL_AGENTS_LOADING = 'GET_REGIONAL_AGENTS_LOADING';
const GET_REGIONAL_AGENTS_SUCCESS = 'GET_REGIONAL_AGENTS_SUCCESS';
const GET_REGIONAL_AGENTS_FAILED = 'GET_REGIONAL_AGENTS_FAILED';


export{

  GET_REGIONAL_AGENTS_LOADING,
  GET_REGIONAL_AGENTS_SUCCESS,
  GET_REGIONAL_AGENTS_FAILED
}