import types from '../constants'

const initialState = {
  meta: {
    status: ''
  },
  data: []
}

export default function search(state = initialState, action) {
  switch (action.type){
  case types.GET_SEARCH_LOADING:
    return {
      ... state,
      ...action.payload
    }
  case types.GET_SEARCH_SUCCESS:
    return {
      ... state,
      ...action.payload
    }
  case types.GET_SEARCH_FAILED:
    return {
      ... state,
      ...action.payload
    }
  default:
    return { ...initialState, ...state }
  }
}