const GET_VENUES_LIST_LOADING = 'venueType/GET_VENUES_LIST_LOADING';
const GET_VENUES_LIST_SUCCESS = 'venueType/GET_VENUES_LIST_SUCCESS';
const GET_VENUES_LIST_ERROR = 'venueType/GET_VENUES_LIST_ERROR';
const POST_VENUE_LOADING = 'venueType/POST_VENUE_LOADING';
const POST_VENUE_SUCCESS = 'venueType/POST_VENUE_SUCCESS';
const POST_VENUE_ERROR = 'venueType/POST_VENUE_ERROR';

export { 
    GET_VENUES_LIST_ERROR, 
    GET_VENUES_LIST_SUCCESS, 
    GET_VENUES_LIST_LOADING, 
    POST_VENUE_ERROR, 
    POST_VENUE_LOADING, 
    POST_VENUE_SUCCESS 
}