const SET_USER_VARIABLE = 'SET_USER_VARIABLE';

const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

const UPDATE_SETTING_REQUEST = 'UPDATE_SETTING_REQUEST';
const UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS';
const UPDATE_SETTING_FAILURE = 'UPDATE_SETTING_FAILURE';

const FETCH_PROFILE_USER = 'FETCH_PROFILE_USER';

export { SET_USER_VARIABLE, FETCH_PROFILE_USER, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE, UPDATE_SETTING_REQUEST, UPDATE_SETTING_SUCCESS, UPDATE_SETTING_FAILURE };
