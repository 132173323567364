const POST_ORDERREVIEW_LOADING = 'orderReview/POST_ORDERREVIEW_LOADING';
const POST_ORDERREVIEW_SUCCESS = 'orderReview/POST_ORDERREVIEW_SUCCESS';
const POST_ORDERREVIEW_ERROR = 'orderReview/POST_ORDERREVIEW_ERROR';
const GET_ORDERREVIEW_LOADING = 'orederReview/GET_ORDERREVIEW_LOADING';
const GET_ORDERREVIEW_SUCCESS = 'orederReview/GET_ORDERREVIEW_SUCCESS';
const GET_ORDERREVIEW_ERROR = 'orederReview/GET_ORDERREVIEW_ERROR';
const POST_CALCULATE_LOADING = 'orederReview/POST_CALCULATE_LOADING';
const POST_CALCULATE_SUCCESS = 'orederReview/POST_CALCULATE_SUCCESS';
const POST_CALCULATE_ERROR = 'orederReview/POST_CALCULATE_ERROR'


export { 
  POST_ORDERREVIEW_LOADING, 
  POST_ORDERREVIEW_SUCCESS, 
  POST_ORDERREVIEW_ERROR,
  GET_ORDERREVIEW_LOADING, 
  GET_ORDERREVIEW_SUCCESS, 
  GET_ORDERREVIEW_ERROR, 
  POST_CALCULATE_LOADING,
  POST_CALCULATE_SUCCESS, 
  POST_CALCULATE_ERROR 
}