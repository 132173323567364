import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import configParams from './configParams';
import user from './user';
import runtime from './runtime';
import order from './order';
import orderReview from './orderReview';
import orderDetail from './orderDetail';
import initDataCompany from './venue';
import venueType from './venueType';
import doPayment from './doPayment';
import licenseCheck from './licenseCheck';
import sendCertificate from './sendCertificate';
import isAdmin from './isAdmin';
import toShow from './toShow';
import editCompany from './editCompany';
import editVenue from './editVenue';
import editVenueType from './editVenueType';
import getRegional from './regionalAgents';
import deleteVenue from './deleteVenue';
import loadingComponent from './loading';
import location from './location';
import search from './search';
import detailVenue from './detailVenue';

export default combineReducers({
  toastr,
  configParams,
  user,
  venueType,
  runtime,
  initDataCompany,
  order,
  orderReview,
  orderDetail,
  doPayment,
  licenseCheck,
  sendCertificate,
  isAdmin,
  toShow,
  editCompany,
  editVenue,
  editVenueType,
  getRegional,
  deleteVenue,
  loadingComponent,
  location,
  search,
  detailVenue
});
