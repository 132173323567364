import types from '../constants';

const initialState = {
  venueList: {
    meta: {
      status: 'loading'
    },
    data: [],
  },
  venuePost: {
    meta: {
      status: 'loading'
    },
    data: [],
  }

}

export default function venueType(state = initialState, action) {
  switch(action.type) {
  case types.GET_VENUES_LIST_LOADING:
    return { ...state,venueList: { ...action.payload } }
  case types.GET_VENUES_LIST_SUCCESS:
    return { ...state, venueList: { ...action.payload } }
  case types.GET_VENUES_LIST_ERROR:
    return { ...state, venueList: { ...action.payload } }
  case types.POST_VENUE_LOADING:
    return { ...state, venuePost: { ...action.payload } }
  case types.POST_VENUE_SUCCESS:
    return{ ...state, venuePost: { ...action.payload } }
  case types.POST_VENUE_ERROR:
    return{ ...state, venuePost: { ...action.payload } }
  default:
    return { ...initialState, ...state }
  }
}