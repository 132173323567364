import types from '../constants';

const initialState = {
  sumorder: {
    meta: {
      status: ''
    },
    data: []
  },
  city: {
    meta: {
      status: ''
    },
    data: []
  },
  company: {
    meta: {
      status: ''
    },
    data: []
  },
  allsum: {
    meta: {
      status: ''
    },
    data: []
  }
};

export default function order(state = initialState, action) {
  switch (action.type) {
  case types.GET_SUM_ORDER_LOADING:
    return {
      ...state,
      sumorder: {
        ...action.payload
      }
    };
  case types.GET_SUM_ORDER_SUCCESS:
    return {
      ...state,
      sumorder: {
        ...action.payload
      }
    };
  case types.GET_SUM_ORDER_FAILED:
    return {
      ...state,
      sumorder: {
        ...action.payload
      }
    };
  case types.GET_ALLSUM_LOADING:
    return {
      ...state,
      allsum: {
        ...action.payload
      }
    };
  case types.GET_ALLSUM_SUCCESS:
    return {
      ...state,
      allsum: {
        ...action.payload
      }
    };
  case types.GET_ALLSUM_FAILED:
    return {
      ...state,
      allsum: {
        ...action.payload
      }
    };
  case types.GET_CITY_LOADING:
    return {
      ...state,
      city: {
        ...action.payload
      }
    };
  case types.GET_CITY_SUCCESS:
    return {
      ...state,
      city: {
        ...action.payload
      }
    };
  case types.GET_CITY_FAILED:
    return {
      ...state,
      city: {
        ...action.payload
      }
    };
  case types.GET_COMPANY_LOADING:
    return {
      ...state,
      city: {
        ...action.payload
      }
    };
  case types.GET_COMPANY_SUCCESS:
    return {
      ...state,
      company: {
        ...action.payload
      }
    };
  case types.GET_COMPANY_FAILED:
    return {
      ...state,
      company: {
        ...action.payload
      }
    };
  default:
    return {
      ...state
    };
  }
}
