import types from '../constants';

const initialState = {
  meta: {
    status: ''
  },
  data: []
};

export default function order(state = initialState, action) {
  switch (action.type) {
  case types.POST_SEND_CERTIFICATE_LOADING:
    return {
      ...state,
      ...action.payload
    };
  case types.POST_SEND_CERTIFICATE_SUCCESS:
    return {
      ...state,
      ...action.payload
    };
  case types.POST_SEND_CERTIFICATE_ERROR:
    return {
      ...state,
      ...action.payload
    };
  default:
    return {
      ...state
    };
  }
}
