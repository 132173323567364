import types from '../constants';

const initialState = {
  venue: {
    meta:{
      status: 'loading'
    },
    data:[]
  },
  facilities: {
    meta:{
      status: 'loading'
    },
    data:[]
  },
  capacity: {
    meta:{
      status: 'loading'
    },
    data:[]
  },
  venueType: {
    meta:{
      status: 'loading'
    },
    data:[]
  }
};


export default function newVenue (state = initialState, action){
  switch(action.type){
  case types.GET_SEARCH_VENUES_LOADING:
    return{
      ...state,
      venue: {
        ...action.payload
      }
    };
  case types.GET_SEARCH_VENUES_SUCCESS:
    return{
      ...state,
      venue: {
        ...action.payload
      }
    };
  case types.GET_SEARCH_VENUES_FAILED:
    return{
      ...state,
      venue: {
        ...action.payload
      }
    };
  case types.GET_FACILITIES_LOADING:
    return{
      ...state,
      facilities: {
        ...action.payload
      }
    };
  case types.GET_FACILITIES_SUCCESS:
    return{
      ...state,
      facilities: {
        ...action.payload
      }
    };
  case types.GET_FACILITIES_FAILED:
    return{
      ...state,
      facilities: {
        ...action.payload
      }
    };
  case types.GET_CAPACITY_LOADING:
    return{
      ...state,
      capacity: {
        ...action.payload
      }
    };
  case types.GET_CAPACITY_SUCCESS:
    return{
      ...state,
      capacity: {
        ...action.payload
      }
    };
  case types.GET_CAPACITY_FAILED:
    return{
      ...state,
      capacity: {
        ...action.payload
      }
    };
  case types.GET_VENUE_TYPE_LOADING:
    return{
      ...state,
      venueType: {
        ...action.payload
      }
    };
  case types.GET_VENUE_TYPE_SUCCESS:
    return{
      ...state,
      venueType: {
        ...action.payload
      }
    };
  case types.GET_VENUE_TYPE_FAILED:
    return{
      ...state,
      venueType: {
        ...action.payload
      }
    };
  default:
    return{
      ...state
    };
  }
}