import types from '../constants';

const initialState ={
  meta:{
    status: 'loading'
  },
  data:[]
};


export default function getRegional (state = initialState, action){
  switch(action.type){
  case types.GET_REGIONAL_AGENTS_LOADING:
    return{
      ...state,
      ...action.payload
    };
  case types.GET_REGIONAL_AGENTS_SUCCESS:
    return{
      ...state,
      ...action.payload
    };
  case types.GET_REGIONAL_AGENTS_FAILED:
    return{
      ...state,
      ...action.payload
    };
  default:
    return{
      ...state
    };
  }
}