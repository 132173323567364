import types from '../constants';

const initialState = {
  meta: {
    status: ''
  },
  data: []
};

export default function toShow(state = initialState, action) {
  switch (action.type) {
  case types.POST_SHOW_STATUS_LOADING:
    return {
      ...state,
      ...action.payload
    };
  case types.POST_SHOW_STATUS_SUCCESS:
    return {
      ...state,
      ...action.payload
    };
  case types.POST_SHOW_STATUS_FAILED:
    return {
      ...state,
      ...action.payload
    };
  default:
    return {
      ...state
    };
  }
}
