import types from '../constants';

const initialState = {
  meta: {
    status: 'loading'
  },
  data: []
};

export default function license(state = initialState, action) {
  switch (action.type) {
    case types.GET_LICENSE_CHECK_LOADING:
      return {
        ...state,
        ...action.payload
      };
    case types.GET_LICENSE_CHECK_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case types.GET_LICENSE_CHECK_FAILED:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        ...state
      };
  }
}
