import types from '../constants';


const initialState = {
  calculate: {
    meta :{
      status: 'loading'
    },
    data: [],
  },
  postOrder: {
    meta :{
      status: 'loading'
    },
    data: [],
  }
}

export default function orderReview(state = initialState, action){
  switch (action.type){
  case types.POST_ORDERREVIEW_LOADING:
    return {
      ...state,
      postOrder: {
        ...action.payload
      }
    }
  case types.POST_ORDERREVIEW_SUCCESS:
    return {
      ...state,
      postOrder: {
        ...action.payload
      }
    }
  case types.POST_ORDERREVIEW_ERROR:
    return {
      ...state,
      postOrder: {
        ...action.payload
      }
    }
  case types.POST_CALCULATE_LOADING:
    return {
      ...state,
      calculate: {
        ...action.payload
      }
    }
  case types.POST_CALCULATE_SUCCESS:
    return {
      ...state,
      calculate: {
        ...action.payload
      }
    }
  case types.POST_CALCULATE_ERROR:
    return {
      ...state,
      calculate: {
        ...action.payload
      }
    }
  default:
    return { ...initialState, ...state }
  }
}

