import types from '../constants'

const initialState = {
    company: {
        meta: {
            status: 'loading'
        },
        data: []
    },
    byId: {
        meta: {
            status: 'loading'
        },
        data: []
    },
    postCompany: {
        meta: {
            status: 'loading'
        },
        data: []
    },
    fetchCompany: {
        meta: {
            status: 'loading'
        },
        data: []
    },
    province: {
        meta: {
            status: 'loading'
        },
        data: []
    },
    cityById: {
        meta: {
            status: 'loading'
        },
        data: []
    }
}

export default function iniDataEditCompany(state = initialState, action) {
    switch(action.type) {
        case types.GET_DATA_COMPANY_LOADING:
            return {
                ...state,
                company: {
                    ...action.payload
                }
            }
        case types.GET_DATA_COMPANY_SUCCESS:
            return {
                ...state,
                company: {
                    ...action.payload
                }
            }
        case types.GET_DATA_COMPANY_ERROR:
            return {
                ...state,
                company: {
                    ...action.payload
                }
            }
        case types.GET_DATA_COMPANY_BY_ID_LOADING:
            return {
                ...state,
                byId: {
                    ...action.payload
                }
            }
        case types.GET_DATA_COMPANY_BY_ID_SUCCESS:
            return {
                ...state,
                byId: {
                    ...action.payload
                }
            }
        case types.GET_DATA_COMPANY_BY_ID_ERROR:
            return {
                ...state,
                byId: {
                    ...action.payload
                }
            }
        case types.POST_DATA_COMPANY_LOADING:
            return {
                ...state,
                postCompany: {
                    ...action.payload
                }
            }
        case types.POST_DATA_COMPANY_SUCCESS:
            return {
                ...state,
                postCompany: {
                    ...action.payload
                }
            }
        case types.POST_DATA_COMPANY_ERROR:
            return {
                ...state,
                postCompany: {
                    ...action.payload
                }
            }
        case types.FETCH_DATA_COMPANY_LOADING:
            return {
                ...state,
                fetchCompany: {
                    ...action.payload
                }
            }
        case types.FETCH_DATA_COMPANY_SUCCESS:
            return {
                ...state,
                fetchCompany: {
                    ...action.payload
                }
            }
        case types.FETCH_DATA_COMPANY_ERROR:
            return {
                ...state,
                fetchCompany: {
                    ...action.payload
                }
            }
        case types.GET_DATA_PROVINCE_LOADING:
            return {
                ...state,
                province: {
                    ...action.payload
                }
            }
        case types.GET_DATA_PROVINCE_SUCCESS:
            return {
                ...state,
                province: {
                    ...action.payload
                }
            }
        case types.GET_DATA_PROVINCE_ERROR:
            return {
                ...state,
                province: {
                    ...action.payload
                }
            }
        case types.GET_DATA_CITY_BY_PROVINCE_LOADING:
            return {
                ...state,
                cityById: {
                    ...action.payload
                }
            }
        case types.GET_DATA_CITY_BY_PROVINCE_SUCCESS:
            return {
                ...state,
                cityById: {
                    ...action.payload
                }
            }
        case types.GET_DATA_CITY_BY_PROVINCE_ERROR:
            return {
                ...state,
                cityById: {
                    ...action.payload
                }
            }
        default:
            return { ...initialState, ...state}
    }
}