const GET_DATA_COMPANY_LOADING = 'editVenue/GET_DATA_COMPANY_LOADING';
const GET_DATA_COMPANY_SUCCESS = 'editVenue/GET_DATA_COMPANY_SUCCESS';
const GET_DATA_COMPANY_ERROR = 'editVenue/GET_DATA_COMPANY_ERROR';


const GET_DATA_VENUE_BY_ID_LOADING = 'editVenue/GET_DATA_VENUE_BY_ID_LOADING';
const GET_DATA_VENUE_BY_ID_SUCCESS = 'editVenue/GET_DATA_VENUE_BY_ID_SUCCESS';
const GET_DATA_VENUE_BY_ID_ERROR = 'editVenue/GET_DATA_VENUE_BY_ID_ERROR';

const FETCH_DATA_VENUE_LOADING = 'editVenue/FETCH_DATA_VENUE_LOADING';
const FETCH_DATA_VENUE_SUCCESS = 'editVenue/FETCH_DATA_VENUE_SUCCESS';
const FETCH_DATA_VENUE_ERROR = 'editVenue/FETCH_DATA_VENUE_ERROR';

const GET_DATA_PROVINCE_LOADING = 'editVenue/GET_DATA_PROVINCE_LOADING';
const GET_DATA_PROVINCE_SUCCESS = 'editVenue/GET_DATA_PROVINCE_SUCCESS';
const GET_DATA_PROVINCE_ERROR = 'editVenue/GET_DATA_PROVINCE_ERROR';

const GET_DATA_CITY_BY_PROVINCE_LOADING = 'editVenue/GET_DATA_CITY_BY_PROVINCE_LOADING';
const GET_DATA_CITY_BY_PROVINCE_SUCCESS = 'editVenue/GET_DATA_CITY_BY_PROVINCE_SUCCESS';
const GET_DATA_CITY_BY_PROVINCE_ERROR = 'editVenue/GET_DATA_CITY_BY_PROVINCE_ERROR';

export {
  GET_DATA_COMPANY_LOADING,
  GET_DATA_COMPANY_SUCCESS,
  GET_DATA_COMPANY_ERROR,
  GET_DATA_VENUE_BY_ID_LOADING,
  GET_DATA_VENUE_BY_ID_SUCCESS,
  GET_DATA_VENUE_BY_ID_ERROR,
  FETCH_DATA_VENUE_LOADING,
  FETCH_DATA_VENUE_SUCCESS,
  FETCH_DATA_VENUE_ERROR,
  GET_DATA_PROVINCE_LOADING,
  GET_DATA_PROVINCE_SUCCESS,
  GET_DATA_PROVINCE_ERROR,
  GET_DATA_CITY_BY_PROVINCE_LOADING,
  GET_DATA_CITY_BY_PROVINCE_SUCCESS,
  GET_DATA_CITY_BY_PROVINCE_ERROR,
}