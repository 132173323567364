export const GET_SEARCH_VENUES_LOADING = 'GET_SEARCH_VENUES_LOADING';
export const GET_SEARCH_VENUES_SUCCESS = 'GET_SEARCH_VENUES_SUCCESS';
export const GET_SEARCH_VENUES_FAILED = 'GET_SEARCH_VENUES_FAILED';
export const GET_FACILITIES_LOADING = 'GET_FACILITIES_LOADING';
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILED = 'GET_FACILITIES_FAILED';
export const GET_CAPACITY_LOADING = 'GET_CAPACITY_LOADING';
export const GET_CAPACITY_SUCCESS = 'GET_CAPACITY_SUCCESS';
export const GET_CAPACITY_FAILED = 'GET_CAPACITY_FAILED';
export const GET_VENUE_TYPE_LOADING = 'GET_VENUE_TYPE_LOADING';
export const GET_VENUE_TYPE_SUCCESS = 'GET_VENUE_TYPE_SUCCESS';
export const GET_VENUE_TYPE_FAILED = 'GET_VENUE_TYPE_FAILED';
