import types from '../constants';

const initialState = {
  company: {
    meta: {
      status: 'loading'
    },
    data: [],
  },
  postCompany: {
    meta: {
      status: 'loading'
    },
    data: [],
  },
  province: {
    meta: {
      status: 'loading'
    },
    data: [],
  },
  city: {
    meta: {
      status: 'loading'
    },
    data: [],
  }
}
export default function initDataCompany(state = initialState, action) {
  switch(action.type) {
  case types.GET_DATA_COMPANY_LOADING:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.GET_DATA_COMPANY_SUCCESS:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.GET_DATA_COMPANY_ERROR:
    return {
      ...state,
      company: {
        ...action.payload
      }
    }
  case types.POST_DATA_COMPANY_LOADING:
    return {
      ...state,
      postCompany: {
        ...action.payload
      }
    }
  case types.POST_DATA_COMPANY_SUCCESS:
    return {
      ...state,
      postCompany: {
        ...action.payload
      }
    }
  case types.POST_DATA_COMPANY_ERROR:
    return {
      ...state,
      postCompany: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_LOADING:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_SUCCESS:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_PROVINCE_ERROR:
    return {
      ...state,
      province: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_LOADING:
    return {
      ...state,
      city: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_SUCCESS:
    return {
      ...state,
      city: {
        ...action.payload
      }
    }
  case types.GET_DATA_CITY_BY_PROVINCE_ERROR:
    return {
      ...state,
      city: {
        ...action.payload
      }
    }
  default:
    return { ...initialState, ...state }
  }
}